import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Paper,
  CardMedia,
  Card as MUCard,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";

import { getContent } from "./services/api";

import { Skeleton } from "./components/Skeleton";

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 500,
  },

  carousels: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,

    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },

  carousel: {
    marginLeft: 50,
    marginRight: 50,

    "@media (max-width: 600px)": {
      marginBottom: 50,
    },
  },
}));

function App() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pill1, setPill1] = useState([]);
  const [pill2, setPill2] = useState([]);

  useEffect(() => {
    setLoading(true);

    getContent()
      .then((data) => {
        const content = data.data.data;

        setPill1(
          content.pilula1.imagens.map((pill) => {
            return {
              imageUrl: pill.url,
            };
          })
        );

        setPill2(
          content.pilula2.imagens.map((pill) => {
            return {
              imageUrl: pill.url,
            };
          })
        );
      })
      .catch(() => {
        // TODO: Emitir erro de carregamento
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <Skeleton />}

      {!loading && (
        <div className={classes.carousels}>
          <div className={classes.carousel}>
            <Carousel fullHeightHover>
              {pill1.map((item, i) => (
                <Paper key={i}>
                  <MUCard className={classes.card}>
                    <CardMedia component="img" src={item.imageUrl} />
                  </MUCard>
                </Paper>
              ))}
            </Carousel>
          </div>
          <div className={classes.carousel}>
            <Carousel>
              {pill2.map((item, i) => (
                <Paper key={i}>
                  <MUCard className={classes.card}>
                    <CardMedia component="img" src={item.imageUrl} />
                  </MUCard>
                </Paper>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
