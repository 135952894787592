import axios from "axios";

export function getContent() {
  const query = `
  {
    _allArtigosMeta {
      count
    }
    allArtigos {
      id
      titulo
      imagem {
        url
      }
    }
    pilula1 {
      imagens {
        url
      }
    }
    pilula2 {
      imagens {
        url
      }
    }
  }  
  `;

  return axios.post(
    "https://graphql.datocms.com",
    { query },
    {
      headers: {
        Authorization: "cb56b696a4f1436475bdddcca36a9a",
      },
    }
  );
}
