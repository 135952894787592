import React from "react";
import { makeStyles } from "@material-ui/core";
import { Skeleton as MUSkeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: { display: "flex", flexDirection: "column" },

  card: {
    width: 500,
    height: 500,
  },

  carousels: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },

  carousel: {
    marginLeft: 50,
    marginRight: 50,
  },
}));

export function Skeleton() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.carousels}>
        <div className={classes.carousel}>
          <MUSkeleton className={classes.card} variant="text" />
        </div>
        <div className={classes.carousel}>
          <MUSkeleton className={classes.card} variant="text" />
        </div>
      </div>
    </div>
  );
}
